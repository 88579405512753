import * as React from 'react';

import { useSignIn, useSignUp } from '@clerk/nextjs';
import { OAuthStrategy } from '@clerk/types';

import { CLERK_VERIFICATION_STATUS, OAUTH_ERROR } from '@@/Authentication/integration/clerk';

import { STORAGE_KEYS, useLocalStorage } from '@/hooks/use-local-storage';

import { ROUTES } from '@/constants';

import Typograph from '@/components/typography';

type Props = {
  onClick?: () => void;
};

const GoogleSignInFeature = ({ onClick }: Props) => {
  const localStorage = useLocalStorage();
  const { signIn } = useSignIn();
  const { signUp, setActive } = useSignUp();

  if (!signIn || !signUp) return null;

  const signInWith = async (strategy: OAuthStrategy) => {
    localStorage && localStorage.setItem(STORAGE_KEYS.GOOGLE_LOGIN, 'true');

    return await signIn.authenticateWithRedirect({
      strategy,
      redirectUrl: ROUTES.SSO_CALLBACK,
      redirectUrlComplete: '/',
    });
  };

  async function handleSignIn(strategy: OAuthStrategy) {
    if (!signIn || !signUp) return null;

    const userExistsButNeedsToSignIn =
      signUp.verifications.externalAccount.status === CLERK_VERIFICATION_STATUS.TRANSFERABLE &&
      signUp.verifications.externalAccount.error?.code === OAUTH_ERROR.ACCOUNT_EXISTS;

    if (userExistsButNeedsToSignIn) {
      const res = await signIn.create({ transfer: true });

      if (res.status === 'complete') {
        setActive({
          session: res.createdSessionId,
        });
      }

      return;
    }

    await signInWith(strategy);
  }

  return (
    <div className="w-full">
      <button
        className="grid w-full justify-center rounded-md border border-neutral-7 p-3 hover:underline"
        onClick={() => {
          handleSignIn('oauth_google');
          onClick && onClick();
        }}>
        <div className="flex items-center gap-2">
          <img alt="google-icon" width={24} height={24} src="/images/icons/google.png" />

          <Typograph.Span variant="medium-body" className="font-semibold">
            Entrar com o Google
          </Typograph.Span>
        </div>
      </button>
    </div>
  );
};

export default GoogleSignInFeature;
