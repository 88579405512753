import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

import Link from 'next/link';

import { RefinementCtx, z } from 'zod';

import { useMutation } from '@tanstack/react-query';

import { useSignIn, useUser } from '@clerk/nextjs';

import { zodResolver } from '@hookform/resolvers/zod';

import { useUserAuth } from '@/context/user-auth-provider';
import { twMerge } from 'tailwind-merge';

import OAuthErrorMessage from '@@/Authentication/components/oauth-error-message';
import { EMAIL_STATUS } from '@@/Authentication/constants';
import GoogleSignInFeature from '@@/Authentication/features/google-sign-in';
import { useVerifyPermission } from '@@/Authentication/hooks';
import { ClerkError, ClerkErrorToMessage } from '@@/Authentication/integration/clerk';
import AuthService from '@@/Authentication/services/auth-service';

import { STORAGE_KEYS, useLocalStorage } from '@/hooks/use-local-storage';
import { useNavigationFlow } from '@/hooks/use-navigation-flow';

import { PARAM_ORIGIN, ROUTES } from '@/constants';

import { eventGa, isEmptyOrNil } from '@/utils';

import Button from '@/components/button';
import Container from '@/components/container';
import FullScreenSpinner from '@/components/full-screen-spinner';
import InputPassword from '@/components/input-password';
import InputWithLabel from '@/components/input-with-label';
import Typograph from '@/components/typography';

const prefix = 'login.form';

type Props = {
  userEmail?: string;
  shouldRedirect?: boolean;
};

interface LoginFormSchema {
  email: string;
  password?: string;
}

enum LOGIN_TYPE {
  GOOGLE = 'GOOGLE',
  PASSWORD = 'PASSWORD',
}

const getFormSchema = (formatMessage: IntlShape['formatMessage'], isEmailStatus: (x: EMAIL_STATUS) => boolean) => {
  const loginFormsSchema: z.ZodType<LoginFormSchema> = z
    .object({
      email: z
        .string()
        .min(1, formatMessage({ id: `${prefix}.email.invalid` }))
        .email(formatMessage({ id: `${prefix}.email.invalid` }))
        .trim(),
      password: z.string().optional(),
    })
    .superRefine(({ password }: LoginFormSchema, ctx: RefinementCtx) => {
      if (!isEmailStatus(EMAIL_STATUS.REGISTERED) && isEmptyOrNil(password)) {
        return true;
      }

      if ((isEmailStatus(EMAIL_STATUS.REGISTERED) && !!password && password.length < 6) || password?.length === 0) {
        return ctx.addIssue({
          fatal: true,
          code: z.ZodIssueCode.custom,
          message: formatMessage({ id: `${prefix}.password.min-length` }),
          path: ['password'],
        });
      }

      return false;
    });

  return loginFormsSchema;
};

const LoginPageView = ({ userEmail }: Props) => {
  const localStorage = useLocalStorage();
  const { user, isSignedIn: isClerkSignedIn } = useUser();
  const { isLoaded, setActive, signIn } = useSignIn();
  const { cleanLegacyData, isSignedIn } = useUserAuth();
  const { isOnlyAdmin } = useVerifyPermission();
  const { nextPage } = useNavigationFlow();

  const [isLoading, setIsLoading] = useState(false);
  const [emailStatus, setEmailStatus] = useState<EMAIL_STATUS | null>(null);
  const isEmailStatus = (status: EMAIL_STATUS) => emailStatus === status;

  const { formatMessage } = useIntl();

  const loginFormsSchema = getFormSchema(formatMessage, isEmailStatus);

  type LoginFormsValues = z.infer<typeof loginFormsSchema>;

  const triggerLoginEvent = (method: LOGIN_TYPE) => {
    eventGa(`storeapp_login_success_wb`, {
      userId: user?.externalId,
      method,
    });
  };

  const handleNextPage = async () => {
    setIsLoading(true);
    const isGoogleLogin = localStorage?.getItem(STORAGE_KEYS.GOOGLE_LOGIN);
    triggerLoginEvent(isGoogleLogin ? LOGIN_TYPE.GOOGLE : LOGIN_TYPE.PASSWORD);

    setTimeout(() => {
      setIsLoading(false);
      if (isSignedIn && isClerkSignedIn && isOnlyAdmin) {
        nextPage(ROUTES.ONLY_ADMIN);
        return;
      }

      nextPage(ROUTES.OPERATOR_PORTAL, PARAM_ORIGIN.LOGIN);
    }, 300);
  };

  const {
    control,
    register,
    formState: { errors },
    setError,
    watch,
    clearErrors,
    handleSubmit,
    setValue,
    getValues,
  } = useForm<LoginFormsValues>({
    resolver: zodResolver(loginFormsSchema),
    defaultValues: {
      email: userEmail,
    },
  });

  useEffect(() => {
    if (isSignedIn && user) handleNextPage();
  }, [isSignedIn, user]);

  useEffect(() => {
    if (signIn?.firstFactorVerification?.error) localStorage?.removeItem(STORAGE_KEYS.GOOGLE_LOGIN);
  }, [signIn?.firstFactorVerification?.error]);

  const { mutate } = useMutation({
    mutationFn: (email: string) => AuthService.verifyFields({ email: email }),
    onSuccess: (data, email) => {
      setEmailStatus(data.response as EMAIL_STATUS);
      if (data.response === EMAIL_STATUS.NOT_FOUND)
        setError('email', {
          type: 'validate',
          message: 'E-mail não cadastrado. Por favor, solicite um convite de acesso ao seu gerente',
        });
      else if (data.response === EMAIL_STATUS.INVITED) nextPage(ROUTES.SIGN_UP, { email: email });
    },
    onMutate: () => setIsLoading(true),
    onSettled: () => setIsLoading(false),
  });

  const onSubmit = async (formData: LoginFormsValues) => {
    if (!isEmailStatus(EMAIL_STATUS.REGISTERED)) {
      mutate(formData?.email);
      return;
    }
    setTimeout(() => {
      handleSignIn(formData);
    }, 500);
  };

  const handleSignIn = async (formData: LoginFormsValues) => {
    setIsLoading(true);
    try {
      const result = await signIn?.create({
        identifier: formData.email,
        password: formData.password,
      });

      if (result?.status === 'complete' && setActive) {
        clearErrors();
        cleanLegacyData();

        await setActive({ session: result.createdSessionId }).catch((err) => {
          console.error(err);
          setError('password', {
            type: 'validate',
            message: 'Senha ou e-mail incorretos.',
          });
        });
      }
    } catch (err) {
      setIsLoading(false);

      const code = (err as ClerkError).errors[0].code;

      const message = ClerkErrorToMessage[code] ?? 'Senha ou e-mail incorretos.';

      setError('password', {
        type: 'validate',
        message,
      });
    }
  };

  if (!isLoaded) return null;

  return (
    <Container withoutHeader skipUserAuthCheck>
      <div className="mb-6">
        <Typograph.H1 variant="h2" className="mb-2">
          Entre na sua conta para fazer uma troca!
        </Typograph.H1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="email"
          defaultValue=""
          render={({ field: { value, onChange, ref } }) => (
            <InputWithLabel
              data-testid="login-email"
              value={value}
              label={formatMessage({ id: `${prefix}.email` })}
              className="mb-3"
              autoComplete="email"
              placeholder="Digite seu e-mail"
              type="email"
              errorMessage={errors.email?.message ?? ''}
              isRequired
              onChange={(e) => {
                onChange(e);
                if (isEmailStatus(EMAIL_STATUS.REGISTERED)) {
                  setEmailStatus(null);
                  setValue('password', '');
                }
              }}
              ref={ref}
            />
          )}
        />

        <div
          data-testid="login-password-container"
          className={twMerge(
            'h-0 overflow-hidden opacity-0 transition-all',
            isEmailStatus(EMAIL_STATUS.REGISTERED) && 'opacity-1 h-[95px]'
          )}>
          <InputPassword
            data-testid="login-password"
            errorMessage={errors.password?.message ?? ''}
            {...register('password')}
          />
        </div>

        <div className="py-4">
          <Link href={`${ROUTES.FORGOT_PASSWORD}?email=${watch('email')}`}>
            <Typograph.Span variant="link">Esqueceu a senha?</Typograph.Span>
          </Link>
        </div>

        <Button className="w-full" type="submit" data-testid="submit-btn">
          <FormattedMessage id={isEmailStatus(EMAIL_STATUS.REGISTERED) ? 'enter' : 'continue'} />
        </Button>
      </form>

      <div className="grid gap-2 pt-2">
        <Typograph.Span variant="big-body" className="text-center text-on-surface-light-medium">
          ou
        </Typograph.Span>
        <GoogleSignInFeature onClick={() => eventGa('storeapp_login_google_clwb')} />

        {!getValues('email') && <OAuthErrorMessage signIn={signIn} className="mt-1" />}
      </div>

      <FullScreenSpinner isVisible={isLoading} />
    </Container>
  );
};

export default LoginPageView;
