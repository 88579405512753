import { ApiResponse } from '@/@types/api-response';

import { tradeInApi } from '@/config/api';

export default class AuthService {
  static async postSecureInvite(email: string, orgId: string, role: string) {
    const { data } = await tradeInApi.post<ApiResponse<string>>(
      `/v1/secure/auth/invite`,
      {
        email,
        role,
      },
      {
        headers: {
          'Doji-Org-Id': orgId,
        },
      }
    );

    return data;
  }

  static async verifyFields(body: { email: string }) {
    const { data } = await tradeInApi.post<ApiResponse<string>>(`/v1/public/auth/verify`, body);

    return data;
  }
}
