import { ComponentProps, memo } from 'react';

import { VariantProps, tv } from 'tailwind-variants';

const button = tv({
  base: 'p-4 cursor-pointer h-12 rounded text-sm capitalize font-semibold leading-4 disabled:opacity-70 disabled:hover:brightness-100 disabled:cursor-not-allowed disabled:text-on-surface-light',
  variants: {
    variant: {
      primary: 'bg-primary hover:brightness-125 text-white disabled:bg-neutral-5',
      secondary:
        'bg-white border border-solid border-primary text-primary disabled:border-on-surface-light disabled:bg-none hover:underline',
    },
    typeColor: {
      warning: 'bg-red',
      ai: 'bg-doji-purple-600',
    },
  },
  compoundVariants: [
    {
      variant: 'primary',
      typeColor: 'warning',
      className: 'bg-error',
    },
    {
      variant: 'secondary',
      typeColor: 'warning',
      className: 'border-error text-error',
    },
    {
      variant: 'secondary',
      typeColor: 'ai',
      className: 'bg-white text-doji-purple-600 border-doji-purple-600 border',
    },
  ],
  defaultVariants: {
    variant: 'primary',
  },
});

type ButtonProps = ComponentProps<'button'> & VariantProps<typeof button>;

const Button = ({ variant, children, typeColor, className, ...props }: ButtonProps) => {
  return (
    <button className={button({ variant, className, typeColor })} {...props}>
      {children}
    </button>
  );
};

export default memo(Button);
