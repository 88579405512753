import Link from 'next/link';

import { SignInResource } from '@clerk/types';

import { twMerge } from 'tailwind-merge';

import {
  CLERK_OAUTH_MESSAGES,
  CLERK_VERIFICATION_STATUS,
  ClerkErrorToMessage,
  OAUTH_ERROR,
  SIGN_UP_ERROR,
} from '@@/Authentication/integration/clerk';

import { ROUTES } from '@/constants';

import Typograph from '@/components/typography';

type Props = { signIn: SignInResource; className?: string };

const OAuthErrorMessage = ({ signIn, className }: Props) => {
  const enabledError: string[] = [CLERK_VERIFICATION_STATUS.UNVERIFIED, CLERK_VERIFICATION_STATUS.TRANSFERABLE];
  const errorIndentifiers = signIn?.firstFactorVerification?.error?.meta?.identifiers;
  const verificationError = signIn?.firstFactorVerification?.error;

  const clerkSignInEmail = errorIndentifiers && errorIndentifiers.length > 0 ? errorIndentifiers[0] : undefined;

  const userNotInvited = clerkSignInEmail && verificationError?.code;
  const userNotRegistered = !clerkSignInEmail && verificationError?.code;

  const hasOAuthError = enabledError.includes(signIn?.firstFactorVerification?.status ?? '');

  if (!hasOAuthError && !verificationError) return null;

  return (
    <div className={twMerge('flex flex-col items-baseline gap-1 sm:flex-row', className)}>
      <Typograph.Span data-testid="oauth-error" variant="medium-body" className="text-nowrap text-error">
        {userNotInvited && CLERK_OAUTH_MESSAGES[SIGN_UP_ERROR.NOT_ALLOWED_ACCESS] + ` ${clerkSignInEmail}.`}

        {userNotRegistered &&
          ClerkErrorToMessage[(verificationError?.code as OAUTH_ERROR) || OAUTH_ERROR.ACCOUNT_NOT_FOUND]}
      </Typograph.Span>

      {userNotRegistered && (
        <Link href={ROUTES.SIGN_UP}>
          <Typograph.Span variant="link" className="text-sm font-normal">
            Clique aqui para se cadastrar.
          </Typograph.Span>
        </Link>
      )}
    </div>
  );
};

export default OAuthErrorMessage;
